<template>
<div>
  
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Service Request">

      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col md="6" >
                
                <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="form.site" @change="resetData()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                
                <b-form-group
                  label="Location"
                  class="required"

                >
                  <v-select
                    v-model="form.locations"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="locations"
                    placeholder="Select"
                    :close-on-select=false
                    :clear-on-select=false
                    class="multiselect_muliple_options"
                  />

                  <p class="text-warning" v-if="form.site != ''" @click="openModel()">
                    <feather-icon icon="PlusCircleIcon" />
                      <span class="align-middle ml-50" style="cursor: pointer">Add Location</span>
                  </p>
                    
                </b-form-group>
              </b-col>

            </b-row>
            
            <b-row>
              <b-col :md="severalities.length > 0 ? '6' : '12'">
                <b-form-group
                  label="Request Detail"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 100 characters"
                    v-model="form.title"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="severalities.length > 0">
                
                  <b-form-group
                    label="Severity"
                    class=""
                  >
                    <v-select
                      v-model="form.severality"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="severalities"
                      placeholder="Select"
                      @input="setTodayDate()"
                      :clearable="false"
                    />
                  </b-form-group>
              </b-col>

              
            </b-row>

            <!-- <b-row>
              <b-col md="12">
                <b-form-group
                  label="Description"
                  class="required"
                >
                  <b-form-textarea
                    placeholder="upto 500 characters"
                    rows="3"
                    v-model="form.description"
                  />
                </b-form-group>
              </b-col>

              
            </b-row> -->

            


            

            <b-row>

              <b-col md="12">
                <b-form-group
                  label="Request Type"
                  class="required"
                >
                <div class="demo-inline-spacing margin_bottom_zero_mobile">
                  <b-form-radio
                    v-model="form.type"
                    name="some-radio9"
                    value="Ad-Hoc"
                    class="custom-control-warning"
                    @change="form.minutes = ''"
                  >
                    Ad-Hoc
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.type"
                    name="some-radio9"
                    value="Reactive"
                    class="custom-control-warning"
                    @change="form.minutes = ''"
                  >
                    Reactive
                  </b-form-radio>

                  <b-form-radio
                    v-model="form.type"
                    name="some-radio9"
                    value="Urgent"
                    class="custom-control-warning"
                    v-if="showUrgent == 'yes'"
                    @change="form.minutes = ''"
                  >
                    Urgent
                  </b-form-radio>

                </div>
              </b-form-group>

              </b-col>
            </b-row>
            <!-- <b-row class="mt-2" v-if="form.type == 'Urgent'">
              <b-col md="6" >
                
                <b-form-group
                  label="Number of minutes"
                  class="required"

                >
                  <b-form-select v-model="form.minutes">

                    <b-form-select-option value="">Select</b-form-select-option>
                    <b-form-select-option :value="minute" v-for="minute in minutes" :key="minute">{{minute}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

            </b-row> -->

            <b-row class="mt-2 margin_top_zero_mobile">
              
              <b-col md="3" >

                <b-form-group
                  label="Start Date"
                  class="required"

                > 
                  
                  <b-form-datepicker
                  
                    v-model="form.dateRange.startDate"
                    :disabled="form.severality != null"
                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                    locale="en"
                  />

                    
                </b-form-group>
              </b-col>

              <b-col md="3" >
                <b-form-group
                    label="End Date"
                    class="required"

                  >
                    
                    <b-form-datepicker
                  
                      v-model="form.dateRange.endDate"
                      :disabled="form.severality != null"
                      :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                      locale="en"
                    />

                      
                </b-form-group>
              </b-col>

              <b-col md="6" >
                
                <b-form-group
                  label="Training Content"
                  class=""

                >
                  <b-form-select v-model="form.training">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>

                  </b-form-select>
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row class="mt-1 mb-1 margin_top_zero_mobile">

              <b-col md="6">
                
                <b-form-group
                  label=""
                  class="margin_bottom_zero_mobile"
                >
                  <b-form-checkbox
                    v-model="form.planned_time_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.plannedStartTime = '00:00'"
                  >
                    Planned Start Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>

            </b-row>

            <b-row  v-if="form.planned_time_modify == 'yes'">

                <b-col md="6">

                  <b-form-group
                      label="Planned Start Time"
                      class="margin_bottom_zero_mobile"

                    >
                      <flat-pickr
                        v-model="form.plannedStartTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                      />
                        
                  </b-form-group>

                  
                </b-col>

            </b-row>
              
            <b-row class="mt-1 mb-1 margin_bottom_zero_mobile">

              <b-col md="6">
                
                <b-form-group
                  label=""
                >
                  <b-form-checkbox
                    v-model="form.start_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.startTime = '00:00'"
                    :disabled="form.severality != null"
                  >
                    Start Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>

              <b-col md="6">
                
                <b-form-group
                  label=""
                >
                  <b-form-checkbox
                    v-model="form.end_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.endTime = '00:00'"
                    :disabled="form.severality != null"
                  >
                    End Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>
          
            </b-row>


            <b-row>

              <b-col md="6" v-if="form.start_modify == 'yes'">

                <b-form-group
                    label="Start Time"
                    class=""

                  >
                    <flat-pickr
                      v-model="form.startTime"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                    />
                      
                </b-form-group>

                
              </b-col>


              <b-col md="6" v-if="form.end_modify == 'yes'">

                <b-form-group
                    label="End Time"
                    class=""

                  >
                    <flat-pickr
                      v-model="form.endTime"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                    />
                      
                </b-form-group>

                
              </b-col>
            </b-row>

            

            
            
            
            
            
            <b-row>
              <b-col class="daily_report_sign">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'adhoc-tasks' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>


      <b-modal
        id="add-location"
        ref="add-location"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Add Location"
        @show="resetModal"
        @hidden="resetModal"
        @ok="addLocation"
      >

        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>
          <b-form-group>
            <label for="email">Location Name:</label>
            <!-- <b-form-input placeholder="" v-model="location_name" /> -->
            <vue-simple-suggest
                  v-model="location_name"
                  :list="location_list"
                  :filter-by-query="true"
                  placeholder="3 to 100 characters"
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
              >
              </vue-simple-suggest>
          </b-form-group>
        </b-form>
      </b-modal>


  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormTextarea,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import moment from "moment-timezone";
import vSelect from 'vue-select';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
  components: {
    Datepicker,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    DateRangePicker,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    flatPickr,
    BBreadcrumb,
    VueSimpleSuggest
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      clearButton:true,
      error_message:null,
      showDismissibleAlert:false,
      popshowDismissibleAlert:false,
      minutes:[],
      form :{
        title:'',
        site:'',
        locations:[],
        supervisor:'',
        training:'',
        shift:'AM',
        type:'Ad-Hoc',
        description:'',
        dateRange: {
            startDate: new Date(),
            endDate: new Date()
        },
        minutes:'',

        startTime:'00:00',
        endTime:'00:00',
        start_modify:'no',
        end_modify:'no',
        plannedStartTime:'00:00',
        planned_time_modify:'no',
        severality:null,

      },
      severalities:[],
      highlighted : {
          dates: [
            new Date()
          ]
      },
      showUrgent:'no',

      //issue_type_array:['Ad-Hoc','Defect','Reactive'],

      start_date:'',
      end_date:'',

      location_list: [],
      
      // range: false,
      // date: moment().tz('Asia/Singapore').subtract(1, 'days').format('YYYY-MM-DD'),
      // format: 'YYYY-MM-DD',
      // auto: true,
      // center: 'center',
      // minDate: moment().tz('Asia/Singapore').subtract(1, 'days').format('YYYY-MM-DD'),
      // locale: {
      //     direction: 'ltr', //direction of text
      //     format: 'DD-MMM-YYYY', //fomart of the dates displayed
      //     separator: ' - ', //separator between the two ranges
      //     applyLabel: 'Apply',
      //     cancelLabel: 'Cancel',
      //     weekLabel: 'W',
      //     customRangeLabel: 'Custom Range',
      //     daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
      //     monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
      //     firstDay: 1, //ISO first day of week - see moment documenations for details
      //     sameDateFormat: {
      //         from: "DD MM YYYY, hh:mm",
      //         to: "hh:mm",
      //     },
      // },
      // timePicker24Hour: false,
      // timePicker: false,
      // dateInput: {
      //   placeholder: "Select Date"
      // },

      sites:[],
      locations:[],
      supervisors:[],
      trainings:[],
      location_name:'',
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/add-adhoc-task'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'adhoc-tasks' })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                if (data.length == 1) {
                            
                    this.form.site = data[0]._id;
                    this.resetData();
                }
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    allLocations(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.locations = data;

                this.location_list = data.map(el => el.name);
                return this.locations;
            }
        });
    },
    allSupervisor(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-supervisors'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                var sup = null;

                for (var i = 0; i < data.length; i++) {
                  if (data[i].main_supervisor == 'yes') {
                    this.form.supervisor = data[i]._id;
                  }
                }

                this.form.supervisor = this.form.supervisor ? this.form.supervisor : '';
                
                this.supervisors = data;

                
                return this.supervisors;
            }
        });
    },
    trainingData(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/all-trainings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.trainings = data;
                return this.trainings;
            }
        });
    },
    resetData(){
      this.form.locations = [];
      this.form.supervisor = '';
      this.form.minutes = '';
      this.form.type = 'Ad-Hoc';

      this.form.startTime = '00:00';
      this.form.endTime = '00:00';
      this.form.start_modify = 'no';
      this.form.end_modify = 'no';
      this.form.plannedStartTime = '00:00';
      this.form.planned_time_modify = 'no';
      this.form.severality = null;

      this.allLocations();
      this.allSupervisor();

      if(this.form.site != '') {

        this.siteDetail();
      }else{
        this.showUrgent = 'no';
      }
    },

    resetModal(){
      // this.form.locations = [];
      this.location_name = '';
      this.popshowDismissibleAlert = false;
    },
    openModel(){
      // this.form.locations = [];
      this.$refs['add-location'].show();
    },
    addLocation(bvModalEvt){
      bvModalEvt.preventDefault()
      
      return this.$store.dispatch(POST_API, {
           data:{
             location_name:this.location_name,
             site:this.form.site
           },
           api: '/api/add-other-location'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                this.allLocations();
                this.$refs['add-location'].hide();
            }
        });
    },
    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    siteDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.form.site
           },
           api: '/api/site-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                if (data.kpi_contract == 'yes') {
                  this.showUrgent = 'yes';
                  this.minutes = [];
                  
                  var n = [];
                  
                  data.minutes.forEach(item => {
                    n.push(item.value);
                  })

                  this.minutes = n;
                  
                }else{
                  this.showUrgent = 'no';
                  
                  this.minutes = [];
                }

                //this.form.severality = null;
                this.severalities = data.severality_levels;


                
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Service Request'
      },{
        to:{name:'adhoc-tasks'},
        text: 'SR Tasks'
      },{
        to:null,
        text: 'Add Service Request',
        active:true
      }];
      return item;
    },
    setTodayDate(){
      this.form.dateRange =  {
            startDate: new Date(),
            endDate: new Date()
        };

      this.form.startTime = '00:00';
      this.form.endTime = '00:00';
      this.form.start_modify = 'no';
      this.form.end_modify = 'no';
      this.form.plannedStartTime = '00:00';
      this.form.planned_time_modify = 'no';
    
    }
    

  },
  mounted(){
    this.allSites();
    this.trainingData();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

